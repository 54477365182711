import { useEffect, useState } from 'react'
import {
  checkToken,
  getAuthUserFromWorkspaces,
  getCookieAccounts,
  getCurrentUserFromWorkspaces,
  getInviteDomainFromCookie,
  getInviteFromCookie,
} from '../../shared/lib/functions'
import {
  createNewAccount,
  getHashcodeInvite,
  getLoginList,
  getPublicDomainInfo,
  inviteLinkInfo,
  requestCodeByEmail,
} from '../../shared/requests'
import { config } from '@clout-team/web-components'
import { isElectron } from '@clout-team/helpers/dist/utils/web-utils'
import { useLoginActions } from '../../state/slices/login'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import { useParams } from 'react-router-dom'

interface UseInitAppArg {
  setIsFullScreen: (val: boolean) => void
  path?: string
}
export function useInitApp(props: UseInitAppArg) {
  const { setIsFullScreen, path } = props

  const dispatch = useAppDispatch()

  const {
    setAllowedDomains,
    setCompanyName,
    setInitialState,
    setInviteHash,
    setInviteProfiles,
    setMail,
    setNameSurname,
    setProfile,
    setStep,
    setType,
    setWorkspaceUserHash,
  } = useLoginActions()

  useEffect(() => {
    try {
      const url = new URL(window.location.href);
      if (url && url?.searchParams?.get('reason')) {
        //Записываем причину редиректа и сохраняем в сессию для дальнейшей отправки в загловках
        sessionStorage.setItem('reason', url.searchParams.get('reason')!);
        url.searchParams.delete('reason');
        window.history.replaceState(null, '', url.toString())
      }
    }
    catch (e) {
      console.error(e)
    }

    ;(async () => {
      const queryString = window.location.search
      const host = window.location.host.replace('.lenzaos.com', '')
      const urlParams = new URLSearchParams(queryString);

      // получим список аккаунтов
      const workspaces: any = []
      const invited: any = {}
      const allowed: any = {}

      if (checkToken()) {
        const accounts = await getCookieAccounts()
        if (accounts.length > 0) {
          for await (const user of accounts) {
            const token = user.token

            if (!invited[user.email]) invited[user.email] = []
            if (!allowed[user.email]) allowed[user.email] = []

            if (token) {
              try {
                const list = await getLoginList(token)

                if (list && list.response) {
                  list.response?.profile?.forEach((p: any) => {
                    if (p.status.toLowerCase() === 'active') {
                      const obj = { ...p, email: user.email }

                      const authorizedWorkspace = accounts.find(
                        (account: any) => account.hash === obj.hash,
                      )
                      if (authorizedWorkspace) {
                        obj.token = authorizedWorkspace.token
                      }

                      if (
                        !workspaces.find((workspace: any) => workspace.hash === obj?.hash)
                      ) {
                        workspaces.push(obj)
                      }
                    } else {
                      if (
                        !invited[user.email]?.find(
                          (workspace: any) => workspace.hash === p.hash,
                        )
                      ) {
                        invited[user.email].push(p)
                      }
                    }
                  })
                  list.response?.invite?.forEach((allow: any) => {
                    allowed[user.email].push({ ...allow, email: user.email })
                  })
                }
              } catch (e) {
                console.log(e)
              }
            }

            if (invited[user.email].length === 0) delete invited[user.email]
            if (allowed[user.email].length === 0) delete allowed[user.email]
          }
          setProfile(workspaces)
          setInviteProfiles(invited)
          setAllowedDomains(allowed)
        }
      }

      if (urlParams.get('email') !== null) {
        const email = urlParams.get('email')
        setMail(email)
      }

      if (urlParams.get('code') !== null) {
        const code = urlParams.get('code')
        setInviteHash(code)
        dispatch(inviteLinkInfo({ hash: code }))
        return
      }

      if (![
        'auth',
        'dev-auth',
        'stable-auth',
        'localhost:3000'
      ].includes(host)) {
        dispatch(getPublicDomainInfo({ name: host }))
        setType('INVITE_DOMAIN')
        return
      }

      if (
        (path &&
          (path === 'open' ||
            path === 'join' ||
            path === 'join-domain' ||
            path === 'add' ||
            path === 'create')) ||
        checkToken()
      ) {
        const user = getAuthUserFromWorkspaces(workspaces)
        if (user) {
          setMail(user.email)
          setCompanyName(user.domain)
        }
      }

      if (path && path === 'open') {
        const user = getCurrentUserFromWorkspaces(workspaces)
        if (user) {
          setNameSurname({
            name: user.name,
            surname: user.surname,
          })

          if (user.token) {
            setStep('FINISH')
          } else {
            // запрашиваем код, вход в новое рабочее пространство
            setWorkspaceUserHash(user.hash)
            dispatch(requestCodeByEmail({ email: user.email }))
          }
        }
      } else if (path && path === 'join') {
        const invite = getInviteFromCookie(invited)
        if (invite) {
          setCompanyName(invite.domain)
          setInviteHash(invite.hash_invite)
          dispatch(inviteLinkInfo({ hash: invite.hash_invite }))
        } else dispatch(setStep('INVITE_INVALID'))
      } else if (path && path === 'join-domain') {
        const domainInvite = getInviteDomainFromCookie(allowed)
        if (domainInvite) {
          setCompanyName(domainInvite.domain)
          setType('INVITE_DOMAIN')
          const authTokenUser = workspaces.find(
            (p: any) => p.email === domainInvite.email && p.token,
          )
          if (authTokenUser) {
            dispatch(
              getHashcodeInvite({
                hash_registration: undefined,
                hash_code: domainInvite.hash,
                token: authTokenUser.token,
                email: domainInvite.email,
              }),
            )
            return
          }
          dispatch(
            getHashcodeInvite({
              hash_registration: undefined,
              hash_code: domainInvite.hash,
              email: domainInvite.email,
            }),
          )
        } else setStep('INVITE_INVALID')
      } else if (path && path === 'add') {
        setInitialState('')
        setStep('START')
      } else if (path && path === 'create') {
        dispatch(createNewAccount({ hash_code: undefined }))
      } else {
        if (checkToken()) {
          if (workspaces.length === 0) {
              setStep('PROMO')
          } else {
            setStep('WORKSPACE')
          }
        } else {
            setStep('PROMO')
        }
      }

      if (isElectron()) {
        const handle = (data: boolean) => setIsFullScreen(data)
        // @ts-ignore
        window?.ipcRenderer?.on('set-is-full-screen', handle)
        return () => {
          // @ts-ignore
          window?.ipcRenderer?.removeListener('set-is-full-screen', handle)
        }
      }
    })()
  }, [])
}

import axios from 'axios'
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

export interface ErrorApi {
  errMsg: string

  [key: string]: any
}

export const privateAPI = async <T>(
  method: 'post' | 'get' | 'put',
  url: string,
  data: T,
  token?: string,
) => {
  try {
    axios.defaults.headers.common.lang = cookies.get('lang') ?? 'ru'
    if (token) {
      axios.defaults.headers.common.token = token
    }
    if (process.env.API_VERSION) axios.defaults.headers.common.version = String(process.env.API_VERSION)
    if (sessionStorage.getItem('reason')) {
      axios.defaults.headers.common.redirectReason = String(sessionStorage.getItem('reason'))
    }
    const response = await axios.request({ method, url, data })
    return response.data
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      // Throw an error with the response data
      throw e.response.data
    } else {
      // Throw a generic error if the error is not an AxiosError or has no response
      throw new Error('An unknown error occurred')
    }
  }
}

export const publicAPI = async (method: any, url: any, data: any, token: string) => {
  try {
    axios.defaults.headers.common.lang = cookies.get('lang') ?? 'ru'
    if (process.env.API_VERSION) axios.defaults.headers.common.version = String(process.env.API_VERSION)
    if (sessionStorage.getItem('reason')) {
      axios.defaults.headers.common.redirectReason = String(sessionStorage.getItem('reason'))
    }
    const response = await axios.request({
      method,
      url,
      data,
      headers: { token },
    })

    return response.data
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      // Throw an error with the response data
      throw e.response.data
    } else {
      // Throw a generic error if the error is not an AxiosError or has no response
      throw new Error('An unknown error occurred')
    }
  }
}
